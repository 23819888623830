
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {namespace} from 'vuex-class';
    import {getModule} from 'vuex-module-decorators';
    import {Watch, Prop} from 'vue-property-decorator';

    import {
        IAccount,
        IVeterinarian,
        IOrganization,
        IUpdateVeterinarianPresentationParams,
        IUpdateOrganizationVeterinarianPresentationParams,
        ISpecialty,
    } from '@/types';

    import {
        VeterinarianModule,
        OrganizationModule,
        SnackbarModule,
    } from '@/store/modules';

    const accountNamespace = namespace('account');
    const organizationNamespace = namespace('organization');
    const veterinarianNamespace = namespace('veterinarian');

    import PhoneInput from '@/components/forms/PhoneInput.vue';

    @Component<AccountProfileProfile>({
        components: {
            PhoneInput
        },
    })
    export default class AccountProfileProfile extends Vue {
        public formValid: boolean = false;
        public isSubmittingAccountProfile: boolean = false;
        public regexCellphone = /^(?:\+33|0)[67]\d{8}$/;

        @veterinarianNamespace.Getter('loggedVeterinarian')
        public loggedVeterinarian!: IVeterinarian;

        @organizationNamespace.Getter('loggedOrganization')
        public loggedOrganization!: IOrganization;

        @accountNamespace.Getter('loggedAccount')
        public loggedAccount!: IAccount;

        @veterinarianNamespace.Getter('specialtiesList')
        public specialtiesList!: ISpecialty[];

        public innerModel!: IVeterinarian;

        @Prop({
            type: Object,
            required: false,
        })
        public veterinarian!: IVeterinarian;

        public submitUpdateAccount() {
            this.isSubmittingAccountProfile = true;
            const veterinarianModule = getModule(VeterinarianModule, this.$store);
            const organizationModule = getModule(OrganizationModule, this.$store);
            const snackbarModule = getModule(SnackbarModule, this.$store);

            if (this.loggedVeterinarian && (this.veterinarian.id === this.loggedVeterinarian.id)) {
                const params: IUpdateVeterinarianPresentationParams = {
                    veterinarian_id: this.innerModel.id,
                    first_name: this.innerModel.first_name,
                    last_name: this.innerModel.last_name,
                    cell_phone: this.innerModel.cell_phone,
                    presentation: this.innerModel.presentation,
                    specialty_id: this.innerModel.specialty.id,
                };

                veterinarianModule
                    .update(params as IUpdateVeterinarianPresentationParams)
                    .then((veterinarian: IVeterinarian) => {
                        snackbarModule.displaySuccess(`Votre profil a bien été modifié !`);
                        this.$emit('input', veterinarian);
                    })
                    .catch(() => {
                        snackbarModule.displayError();
                    })
                    .finally(() => {
                        this.isSubmittingAccountProfile = false;
                    })
                ;
            } else {
                const params: IUpdateOrganizationVeterinarianPresentationParams = {
                    organization_id: this.loggedOrganization.id,
                    veterinarian_id: this.innerModel.id,
                    first_name: this.innerModel.first_name,
                    last_name: this.innerModel.last_name,
                    cell_phone: this.innerModel.cell_phone,
                    presentation: this.innerModel.presentation,
                    specialty_id: this.innerModel.specialty.id,
                };

                organizationModule
                    .updateOrganizationVeterinarian(params as IUpdateOrganizationVeterinarianPresentationParams)
                    .then((veterinarian: IVeterinarian) => {
                        snackbarModule.displaySuccess(`Votre profil a bien été modifié !`);
                        this.$emit('input', veterinarian);
                    })
                    .catch(() => {
                        snackbarModule.displayError();
                    })
                    .finally(() => {
                        this.isSubmittingAccountProfile = false;
                    })
                ;
            }

        }

        private data() {
            return {
                innerModel: Object.assign({}, this.veterinarian),
            };
        }

        @Watch('veterinarian', {immediate: true})
        private onVeterinarianChange(newVal: IVeterinarian) {
            this.innerModel = Object.assign({}, newVal);
        }
    }
